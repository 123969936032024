@import 'Settings';
@import "@scrippsproduct/entertainment-sites-css/src/base.scss";

html {    
    color: var(--mono-color-400);
}

body {
    background-image: var(--body-gradient);
    background-color: var(--mono-color-100);
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
}

content-constrainer {    
    border-bottom: 1px solid var(--theme-color-100);
}

.content-constrainer--margin-bottom {
    margin-block-end: 4rem;
}

.page-content-header {
    font-family: 'Roboto', sans-serif;
    font-size: 3.2rem;
    font-size: clamp(1.8rem, 4vw + 1rem, 3.775rem);
    font-weight: 900;
    letter-spacing: 1.4px;
    line-height: 1.2;
}

#upcoming-modal {
    .modal {
        padding: 3.5rem 2.5rem 2.5rem;
        min-height: 60vh;
        border: 4px solid var(--theme-color-500);
        border-radius: var(--border-radius-large);
        background-color: var(--theme-color-400);
        transition: min-height 0.325s ease-in-out;
    }

    &:has(.generic-list.upcoming) {
        .modal {
            min-height: unset;
        }
    }

    .h2 {
        color: var(--mono-color-100);
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-size: clamp(2rem, 4vw, 3.8rem);
        text-transform: uppercase;
        line-height: 1.1;
        margin: 0;
        padding-block-end: 0.1em;
        border-bottom: 1px solid var(--mono-color-100);
    }

    .h5 {
        font-family: 'Lato', sans-serif;
        font-weight: 600;
        font-size: 1.2rem;
        color: var(--mono-color-100);
        margin-block: 1.3em 0.4em;
    }

    .generic-list {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .generic-list__item {
        color: var(--mono-color-100);
        padding: 0.7rem 1.5rem 0.3rem 1.5rem;
        font-size: 1.25rem;
        text-transform: uppercase;
        border-radius: 20rem;

        &:nth-child(odd) {
            background-color: var(--theme-color-500);
        }

        airdate-formatter {
            display: flex;
        }
    }

    .airdate-formatter__time-wrap {
        display: inline-block;
        margin-left: auto;
        text-transform: none;
    }
}

.header-divider {
    border-color: var(--mono-color-100);
}


.modal__close-btn {
    left: unset;
    top: 0.1rem;
    right: 0.4rem;
    transform-origin: right;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    
    &:hover,
    &:focus,
    &:focus-visible {
        transform: scale(1.1);
        border-radius: 0;
    }

    > .sr-only {
        border: 0 !important;
        clip: unset !important;
        clip-path: unset !important;
        height: unset !important;
        margin: unset !important;
        overflow: visible !important;
        padding: 0 !important;
        position: static !important;
        width: auto !important;
        white-space: nowrap !important;
        left: unset;
        right: 2.7rem;
        font-family: 'Roboto';
        font-size: 1.14rem;
        font-weight: 500;
        top: 0.8rem;
        text-transform: uppercase;

        @media screen and (max-width: 980px) {
            top: 1rem;
        }

        // target iOS devices
        @supports (-webkit-touch-callout: none) {
            right: 3.5rem;
        }
    }

    svg {
        width: 28px;
        stroke-width: 2px;
    }
}

.modal .inline-anchor {
    color: var(--mono-color-100);
}
