@import "@scrippsproduct/entertainment-sites-css/src/header.scss";

header logo-navigation {  
    .image-replacement-anchor {
        transition: scale 0.225s ease-out;
    }

    .image-replacement-anchor:hover,
    .image-replacement-anchor:focus {
        scale: 1.1;
    }
}

.main-navigation {
    margin: 0 2rem 0 auto;
}

.main-navigation .main-navigation__nav-list {
    gap: 0.5rem;
}

.main-navigation__nav-link {
    --transition-length: 0.275s;
    font-family: 'Roboto', sans-serif;
    padding: 0.5rem 1.5rem;
    background-color: transparent;
    transition: background-color var(--transition-length) ease-out, color var(--transition-length) ease-out;
    border-radius: 20rem;

    &:before {
        content: unset;
    }

    &:hover,
    &:focus {
        scale: unset;
        text-shadow: unset;
        background-color: var(--mono-color-100);
        color: var(--theme-color-100);

        &:before {
            transform: none;
        }
    }
}

@media screen and (max-width: 60rem) {

    .drawer-navigation__primary-navigation {
        border-radius: 0 0 var(--border-radius-large) var(--border-radius-large);
        --clip-path-end: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%) !important;
    }

    .main-navigation__nav-link {
        background-color: var(--mono-color-100);
        color: var(--theme-color-100);
    }

    header logo-navigation .image-replacement-anchor {
        --ira-baseline-adjustment: 0 !important;
    }

    .mobile-nav {
        &.mobile-nav--shadow {
            background-image: linear-gradient(to right, hsla(0, 100%, 35%, 0.9) 7%,hsla(1, 98%, 37%, 0.9) 27%,hsla(7, 87%, 47%, 0.9) 40%,hsla(7, 87%, 47%, 0.9) 50%,hsla(7, 87%, 47%, 0.9) 60%,hsla(1, 98%, 37%, 0.9) 73%,hsla(1, 98%, 37%, 0.9) 73%,hsla(0, 100%, 35%, 0.9) 92%);
        }

        content-constrainer {
            padding-block-start: 1.5rem;
        }
    }
}