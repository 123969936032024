@import "@scrippsproduct/entertainment-sites-css/src/footer.scss";

.main-footer {
    background-image: none;
}

.footer-nav-wrapper {

    .social-links__header {
        font-size: 1rem;
        margin-block-start: 0;
    }

    .social-links {
        align-self: start;
    }

    .social-link {
        border-radius: 2rem;

        &:hover {
            border-radius: 8px;
        }
    }
}
.footer-nav {

    .h4 {
        font-weight: 900;
        letter-spacing: 1px;
        margin-block-start: 0;
    }
}

.footer-nav__nav-link {
    font-weight: 400;
}