:root {
    /* theme colors */
    --theme-color-100: hsl(0, 100%, 35%);
    --theme-color-200: hsl(1, 98%, 37%);
    --theme-color-300: hsl(7, 87%, 47%);
    --theme-color-400: hsl(357, 75%, 55%);
    --theme-color-500: hsl(7.46, 95.68%, 63.73%);
    /* monotones */
    --mono-color-100: #fff;
    --mono-color-200: #eee;
    --mono-color-250: #bbb;
    --mono-color-300: #999;
    --mono-color-400: #666;
    --mono-color-500: #777;
    --mono-color-700: #444;
    --mono-color-800: #222;
    --mono-color-900: #000;
    /* form colors */
    --form-valid-color: hsl(150, 55%, 38%);
    --form-invalid-color: hsl(0, 51%, 50%);
    /* gradients */
    --on-now-gradient: linear-gradient(to bottom, var(--theme-color-100) 0%, var(--theme-color-100) 100%); //linear-gradient(to bottom, var(--form-valid-color) 0%, hsl(150, 54%, 27%) 100%);
    --footer-gradient: linear-gradient(180deg,rgba(0,0,0, 0.6),rgba(0,0,0, 0)); //linear-gradient(180deg, #D54804 0%, #EB660A 50%);
    --menu-gradient: linear-gradient(to bottom, var(--theme-color-100) 0%, var(--theme-color-100) 100%);
    /* other */
    --cta-button-color: var(--theme-color-100);
    --body-gradient: linear-gradient(to right, var(--theme-color-100) 7%,var(--theme-color-200) 27%,var(--theme-color-300) 40%,var(--theme-color-300) 50%,var(--theme-color-300) 60%,var(--theme-color-200) 73%,var(--theme-color-200) 73%,var(--theme-color-100) 92%);
    --border-radius: 0.75rem;
    --border-radius-small: 0.33rem;
    --border-radius-large: 1.1rem;
    --main-font-family: 'Roboto', sans-serif;
    --body-background-url: url();
    --body-background-blend-mode: normal;
    --body-background-repeat: no-repeat;
    --body-background-size: cover;
    --body-background-position: center;
    --body-background-attachment: fixed;
    --paragraph-font-family: 'Open Sans', sans-serif;
    --anchor-color: var(--theme-color-200);
    --anchor-hover-color: var(--theme-color-100);
    --headers-color: var(--mono-color-700);
    --error-handler-background-color:  var(--mono-color-100);
    --error-handler-outline-color: var(--theme-color-100);
    --modals-close-btn-color: var(--mono-color-100);
    --modals-close-btn-outline-color: var(--theme-color-300);
    --inline-anchor-color: var(--theme-color-400);
}